import { GetQueryParameter } from 'containers/shell';
import { useCallback, useEffect } from 'react';
import {
   isMobile as isAMobileDevice,
   isTablet as isATabletDevice,
   isDesktop as isADesktopDevice,
} from 'react-device-detect';

import { useAppDispatch } from 'store';
import { logout } from 'store/reducers/user.reducer';
import { RN_WEBVIEW_EVENTS } from 'utils/constants';
import { PORTAL_ROLES, VIEW_MODES } from 'utils/enums';
import { IProfile } from 'utils/interfaces';
import { sendEventToRnWebview } from 'utils/utils';

interface IUseSecurity {
   user?: IProfile;
   skip?: boolean;
}

export default function useSecurity(
   { user, skip }: IUseSecurity = { user: undefined, skip: false },
) {
   const dispatch = useAppDispatch();
   const queryViewMode = GetQueryParameter();
   const viewMode = queryViewMode.get('viewMode');
   const IS_WEB_VIEW_MODE = viewMode === VIEW_MODES.WEB_VIEW_MODE;

   const handleLogout = useCallback(() => {
      dispatch(logout());
      sendEventToRnWebview({
         type: RN_WEBVIEW_EVENTS.LOGOUT,
      });
   }, [dispatch]);

   const handleSecurity = useCallback(
      (currentUser?: IProfile) => {
         const isUsingWebViewModeInDesktop =
            IS_WEB_VIEW_MODE && !isAMobileDevice && !isATabletDevice;
         if (isUsingWebViewModeInDesktop) {
            handleLogout();
            return;
         }
         if (user || currentUser) {
            const userData = currentUser || user;
            const isAdmin = userData?.roles.includes(PORTAL_ROLES.ADMIN);
            const isHotelManager = userData?.roles.includes(PORTAL_ROLES.HOTEL_MANAGER);
            if (!IS_WEB_VIEW_MODE && !isAdmin && !isHotelManager) {
               handleLogout();
               return;
            }
            if (IS_WEB_VIEW_MODE && (isAdmin || isHotelManager)) {
               handleLogout();
               return;
            }
         }
      },
      [IS_WEB_VIEW_MODE, handleLogout, user],
   );

   useEffect(() => {
      if (skip) return;

      handleSecurity();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return {
      isAMobileDevice,
      isATabletDevice,
      isADesktopDevice,
      actions: {
         handleSecurity,
         handleLogout,
      },
   };
}
