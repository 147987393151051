import { useQuery } from '@apollo/client';
import { LANG } from 'core/general-setup';
import { ME } from 'graphql/queries/me';
import { useAppDispatch, useAppSelector } from 'store';
import { addUser } from 'store/reducers/user.reducer';
import { AvailableLanguages, PORTAL_ROLES } from 'utils/enums';
import { ArrayString } from 'utils/interfaces';
import { Nullable } from 'utils/types';
import useSecurity from './useSecurity';

export default function useIitMe(skip = false) {
   const dispatch = useAppDispatch();
   const { info: user } = useAppSelector((state) => state.user);

   const {
      actions: { handleSecurity, handleLogout },
   } = useSecurity({ skip: true });

   const { refetch: getMe } = useQuery(ME, {
      skip,
      onCompleted: (me: Nullable<ArrayString>) => {
         if (me) {
            handleSecurity(me?.me);

            let userInfo = { ...me?.me, roles: me?.me.roles };
            userInfo = {
               ...userInfo,
               fullName: `${userInfo?.firstName} ${userInfo?.surname}`,
            };
            dispatch(addUser(userInfo));
            localStorage.setItem(LANG, AvailableLanguages.PT);
         }
      },
      onError: () => {
         handleLogout();
      },
   });

   return {
      isAdmin: user?.roles.includes(PORTAL_ROLES.ADMIN),
      isHotelManager: user?.roles.includes(PORTAL_ROLES.HOTEL_MANAGER),
      getMe,
      handleLogout,
   };
}
