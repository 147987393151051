import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import {
   Box,
   Button,
   Container,
   CssBaseline,
   Grid,
   Link,
   Typography,
} from '@mui/material';

import { displayToaster } from 'utils/utils';
import { ACCOUNT_CONFIRMATION, AlertModalSeverity, VIEW_MODES } from 'utils/enums';
import { IAlertToaster } from 'utils/interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import { addToken, login } from 'store/reducers/user.reducer';

import loginStyles from './styles';

import companyLogo from '../../assets/graphics/logoWithName.png';
import { IitInput } from '../../components/shared-components/iit-input';
import ENV from '../../config/environment';
import useCommonStyles from '../../style/common';
import {
   DEFAULT_NAVIGATE_WEB_VIEW_MODE,
   NAVIGATE_TO_HOME,
   REQUIRED_FIELD,
} from '../../utils/constants';
import { LoginFormName } from '../../utils/enums';
import { InputType } from '../../utils/enums';
import useIitRouting from 'hooks/iit-use-routing';
import { GetQueryParameter } from 'containers/shell';
import useIitMe from 'hooks/use-iit-me';
import useSecurity from 'hooks/useSecurity';

function Copyright() {
   return (
      <Typography variant="body2" color="textSecondary" align="center">
         {'Copyright © '}
         <Link color="inherit" href={ENV.REACT_APP_BRAND_WEBSITE}>
            {ENV.REACT_APP_BRAND}
         </Link>{' '}
         {new Date().getFullYear()}
      </Typography>
   );
}

export default function Login() {
   const classes = loginStyles();
   const dispatch = useAppDispatch();
   const utilsClasses = useCommonStyles();
   const userState = useAppSelector((state) => state.user);
   const { t } = useTranslation();
   const { control, handleSubmit } = useForm();
   const { navigateTo } = useIitRouting();

   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const defaultEmail = searchParams.get('email');
   const confirmationSuccess = searchParams.get('confirmationSuccess');
   const queryViewMode = GetQueryParameter();
   const viewMode = queryViewMode.get('viewMode');
   const IS_WEB_VIEW_MODE = viewMode === VIEW_MODES.WEB_VIEW_MODE;

   const getPages = useCallback(() => {
      if (userState?.info?.isPasswordGenerated) {
         displayToaster({
            message: t('AUTH.PLEASE_CHANGE_YOUR_PASSWORD'),
            severity: AlertModalSeverity.WARNING,
         } as IAlertToaster);
      }

      navigateTo(NAVIGATE_TO_HOME, true);
   }, [navigateTo, t, userState?.info?.isPasswordGenerated]);

   const { getMe, handleLogout } = useIitMe(true);
   const { isAMobileDevice } = useSecurity({
      skip: true,
   });

   const submit = handleSubmit(
      useCallback(
         async ({ password, email }) => {
            dispatch(login({ password, email }))
               .unwrap()
               .then(() => {
                  getMe();
               })
               .catch((error) => {
                  displayToaster({
                     message: t(error.message as string),
                     severity: AlertModalSeverity.WARNING,
                  } as IAlertToaster);
               });
         },
         [dispatch, getMe, t],
      ),
   );

   const loginKeyPress = useCallback(
      (ev: React.KeyboardEvent<HTMLDivElement>) => ev.code === 'Enter' && submit(),
      [submit],
   );

   useEffect(() => {
      switch (confirmationSuccess) {
         case ACCOUNT_CONFIRMATION.CONFIRMED:
            displayToaster({
               message: t('AUTH.CONFIRMED_WITH_SUCCESS'),
               severity: AlertModalSeverity.SUCCESS,
            } as IAlertToaster);
            break;
         case ACCOUNT_CONFIRMATION.NOT_CONFIRMED:
            displayToaster({
               message: t('AUTH.COULD_NOT_CONFIRM'),
               severity: AlertModalSeverity.ERROR,
            } as IAlertToaster);
            break;
         default:
            break;
      }
      if (!userState?.info) return;
      getPages();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userState]);

   useEffect(() => {
      if (!IS_WEB_VIEW_MODE || userState?.info?.isBlocked) {
         handleLogout();
         return;
      }

      if (localStorage.getItem(ENV.REACT_APP_TOKEN_KEY))
         dispatch(addToken(localStorage.getItem(ENV.REACT_APP_TOKEN_KEY) as string));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   if (isAMobileDevice && IS_WEB_VIEW_MODE)
      return <Navigate to={DEFAULT_NAVIGATE_WEB_VIEW_MODE} />;
   if (
      isAMobileDevice &&
      !localStorage.getItem(ENV.REACT_APP_TOKEN_KEY) &&
      IS_WEB_VIEW_MODE
   )
      return <></>;
   return (
      <Container component="main" maxWidth="sm" className={utilsClasses.displayHeight}>
         <Grid
            className={utilsClasses.height100}
            container
            justifyContent="center"
            alignItems="center"
         >
            <CssBaseline />
            <div className={classes.paper}>
               <img src={companyLogo} alt="Logo" className={classes.logo} />
               <Box className={classes.form}>
                  <Grid container spacing={2}>
                     <Grid item xs={12}>
                        <IitInput
                           name={LoginFormName.EMAIL}
                           label={t('GENERAL.EMAIL')}
                           defaultValue={defaultEmail ?? ''}
                           control={control}
                           className={classes.inputs}
                           rules={{
                              required: t('GENERAL.REQUIRED_FIELD') as string,
                              pattern: {
                                 value: ENV.REACT_APP_EMAIL_REGEX,
                                 message: t('GENERAL.INVALID_EMAIL_ADDRESS') as string,
                              },
                           }}
                           onKeyPress={loginKeyPress}
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <IitInput
                           name={LoginFormName.PASSWORD}
                           label={t('GENERAL.PASSWORD')}
                           type={InputType.PASSWORD}
                           defaultValue=""
                           control={control}
                           className={classes.inputs}
                           rules={REQUIRED_FIELD}
                           onKeyPress={loginKeyPress}
                        />
                     </Grid>
                  </Grid>
                  <Button
                     disableElevation
                     size="large"
                     type="submit"
                     fullWidth
                     variant="contained"
                     color="primary"
                     className={classes.submit}
                     onClick={submit}
                  >
                     {t('GENERAL.LOGIN')}
                  </Button>
               </Box>
            </div>
            <Box padding={5}>
               <Copyright />
            </Box>
         </Grid>
      </Container>
   );
}
